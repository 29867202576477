/* tslint:disable */

// AUTO-GENERATED FILE. DO NOT EDIT.

export const ACTIVE_SERVER_ID: number = 201;
export const STATIC_DOMAINS = {"s3": ["mkskimgmodrykonik.vshcdn.net"], "asset": ["mkskasmodrykonik.vshcdn.net"], "files": ["filesmodrykonik.vshcdn.net"]};
export const IS_PROD = true;
export const JAM_ENDPOINT = "wss://sjam.modrykonik.com";
export const API_ENDPOINT = "https://www.modrykonik.sk";
export const BASE_URL = "https://www.modrykonik.sk";
export const FACEBOOK_APP_ID = "156236697748777";
export const GCSE_CX = "25c0b180fa5a1b106";
export const WEBPUSH_PUBLIC_KEY = "BGOLx63cuBajxPUuWOCZfiliISRFHAuq3veXqmGKfm6akataybsjliIh2w440CP9pQ7q5sV7yGNDXUJ3sUvaT3M";
export const CURRENT_EUR_TO_CZK_RATE = 25.295;
export const ESHOP_URL = "https://shop.modrykonik.sk/";
